import React from "react"
import { Caret } from "./Caret"

function FilterMenu(props) {
  const { categoryName, subCategories, setFilter, useCategoryNameToSetFilter, filterType } = props

  return (
    <>
      <div className="filter__container">
        <button
          className={filterType === categoryName ? "filter__button filter__button--all" : "filter__button"}
          type="button"
          onClick={() => useCategoryNameToSetFilter && setFilter(categoryName)}>
          {categoryName}
        </button>
        {subCategories !== undefined && (
          <>
            <Caret />
            <span className="filter__type">{subCategories.filter(item => item === filterType || item === `All ${categoryName}`)}</span>
            <ul className="filter__menu">
              {subCategories.map(subCategory => (
                <li key={subCategory} className="filter__menu-item">
                  <button className="filter__menu-button" type="button" onClick={() => setFilter(subCategory)}>
                    {subCategory}
                  </button>
                </li>
              ))}
              <li className="filter__menu-item">
                <button className="filter__menu-button" type="button" onClick={() => setFilter(categoryName)}>
                  All {categoryName}
                </button>
              </li>
            </ul>
          </>
        )}
      </div>
    </>
  )
}

export { FilterMenu }
