import React, { useState } from "react"
import { graphql } from "gatsby"

// Utility Functions
import { handleMenuItems } from "../utils/handleMenuItems"

// Custom components
import { Nav } from "../components/Nav"
import { Header } from "../components/Header"
import { FilterMenu } from "../components/FilterMenu"
import { Row } from "../components/Row"
import { Footer } from "../components/Footer"

// Portfolio page template
export default function TemplatePortfolio(props) {
  const {
    data: {
      wordpressPage: {
        slug,
        acf: { portfolio_headline: portfolioHeadline },
      },
      // Projects
      allProjects: { edges: projectEdges },
      // Parent
      commercial: { edges: commercial },
      residential: { edges: residential },
      // Child
      bath: { edges: bath },
      bedroom: { edges: bedroom },
      fullHome: { edges: fullHome },
      kitchen: { edges: kitchen },
      livingSpaces: { edges: livingSpaces },
      wordpressAcfOptions: {
        options: {
          sitewide_company_information_company_name: companyName,
          sitewide_company_information_company_twitter: companyTwitter,
          sitewide_company_information_company_linkedin: companyLinkedIn,
        },
      },
      allWordpressPage: { edges },
    },
  } = props

  const [activeFilter, setActiveFilter] = useState(projectEdges)
  const [filterType, setFilterType] = useState("")

  function setFilter(arg) {
    switch (arg) {
      case "All Projects":
        setActiveFilter(projectEdges)
        setFilterType(arg)
        break
      case "Residential":
        setActiveFilter(residential)
        setFilterType(arg)
        break
      case "Commercial":
        setActiveFilter(commercial)
        setFilterType(arg)
        break
      case "Bath":
        setActiveFilter(bath)
        setFilterType(arg)
        break
      case "Bedroom":
        setActiveFilter(bedroom)
        setFilterType(arg)
        break
      case "Full Home":
        setActiveFilter(fullHome)
        setFilterType(arg)
        break
      case "Kitchen":
        setActiveFilter(kitchen)
        setFilterType(arg)
        break
      case "Living Spaces":
        setActiveFilter(livingSpaces)
        setFilterType(arg)
        break

      default:
        break
    }
  }

  return (
    <section className="white portfolio">
      <Nav slug={slug} menuItemsSorted={handleMenuItems({ items: edges })} />
      <Header headerHeadline={portfolioHeadline} />

      <div className="filter">
        <div className="filter__wrapper">
          <span className="filter__copy">Filter:</span>
          <FilterMenu categoryName="All Projects" setFilter={setFilter} useCategoryNameToSetFilter={true} filterType={filterType} />
          <FilterMenu categoryName="Residential" setFilter={setFilter} useCategoryNameToSetFilter={true} filterType={filterType} />
          <FilterMenu categoryName="Commercial" setFilter={setFilter} useCategoryNameToSetFilter={true} filterType={filterType} />
        </div>
      </div>

      <Row rowPage="portfolio" featuredProjs={activeFilter} />

      <Footer
        companyName={companyName}
        companyTwitter={companyTwitter}
        companyLinkedIn={companyLinkedIn}
        slug={slug}
        menuItemsSorted={handleMenuItems({ items: edges })}
      />
    </section>
  )
}

export const pageQuery = graphql`
  query {
    wordpressPage(title: { eq: "Portfolio" }) {
      slug
      acf {
        portfolio_headline
      }
    }
    wordpressAcfOptions {
      options {
        sitewide_company_information_company_name
        sitewide_company_information_company_twitter
        sitewide_company_information_company_linkedin
      }
    }

    allProjects: allWordpressWpProjects {
      edges {
        node {
          title
          project_category
          link
          acf {
            projects_project_details_description
            projects_project_details_winner
            projects_project_details_image {
              localFile {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
    residential: allWordpressWpProjects(filter: { project_category: { eq: 3 } }) {
      edges {
        node {
          title
          project_category
          link
          type
          acf {
            projects_project_details_description
            projects_project_details_winner
            projects_project_details_image {
              localFile {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
    livingSpaces: allWordpressWpProjects(filter: { project_category: { eq: 5 } }) {
      edges {
        node {
          title
          project_category
          link
          type
          acf {
            projects_project_details_description
            projects_project_details_winner
            projects_project_details_image {
              localFile {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
    kitchen: allWordpressWpProjects(filter: { project_category: { eq: 6 } }) {
      edges {
        node {
          title
          project_category
          link
          type
          acf {
            projects_project_details_description
            projects_project_details_winner
            projects_project_details_image {
              localFile {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
    bath: allWordpressWpProjects(filter: { project_category: { eq: 7 } }) {
      edges {
        node {
          title
          project_category
          link
          type
          acf {
            projects_project_details_description
            projects_project_details_winner
            projects_project_details_image {
              localFile {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
    bedroom: allWordpressWpProjects(filter: { project_category: { eq: 8 } }) {
      edges {
        node {
          title
          project_category
          link
          type
          acf {
            projects_project_details_description
            projects_project_details_winner
            projects_project_details_image {
              localFile {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
    fullHome: allWordpressWpProjects(filter: { project_category: { eq: 9 } }) {
      edges {
        node {
          title
          project_category
          link
          type
          acf {
            projects_project_details_description
            projects_project_details_winner
            projects_project_details_image {
              localFile {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
    commercial: allWordpressWpProjects(filter: { project_category: { eq: 4 } }) {
      edges {
        node {
          title
          project_category
          link
          type
          acf {
            projects_project_details_description
            projects_project_details_winner
            projects_project_details_image {
              localFile {
                childImageSharp {
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
    residentialCategories: allWordpressWpProjectCategory(filter: { wordpress_parent: { eq: 3 } }) {
      edges {
        node {
          name
        }
      }
    }
    allWordpressPage(filter: { wordpress_parent: { eq: 0 } }) {
      edges {
        node {
          title
          menu_order
          slug
        }
      }
    }
  }
`
